import moment from "moment/moment";
import { FAILURE, POST, SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";
// import { TRUE } from "../utils/const/bool-const";

export async function obtenerListaChecklist() {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/checklist/obtener-lista-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            for (let i = 0; i < body.length; i++) {
                body[i].fechaProceso = moment(body[i].fechaProceso).format('DD/MM/YYYY HH:mm');
            }
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaChecklist err");
        console.log(err);
        return [];
    }
}

export async function crearNuevoChecklist(obj) {
    try {
        let creado = { error: true };
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/checklist/guardar-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            creado.error = false;
            creado.id = body.id;
        }
        return creado;
    } catch (err) {
        console.log("crearCorreo err");
        console.log(err);
        return { error: true };;
    }
}

export async function obtenerInfoChecklist(obj) {
    try {
        let rows = null;
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/checklist/obtener-info-checklist/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body[0];
        }
        return rows;
    } catch (err) {
        console.log("obtenerInfoChecklist err");
        console.log(err);
        return null;
    }
}

export async function modificarChecklist(obj) {
    try {
        let modificado = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/checklist/modificar-checklist/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            modificado = true;
        }
        return modificado;
    } catch (err) {
        console.log("modificarChecklist err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarChecklist(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/checklist/deshabilitar-checklist/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarChecklist err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaIconos() {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/checklist/obtener-lista-iconos/",
            {
                headers: getHeaders({ authenticate: true }),
                method: "POST",
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            rows = body;
            for (let i = 0; i < rows.length; i++) {
                const element = rows[i];
                rows[i].fechaproceso = moment(element.fechaProceso).format(
                    "DD/MM/YYYY HH:mm"
                );
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaIconos err");
        console.log(err);
        return [];
    }
}

export async function actualizarPreguntasChecklist(obj) {
    try {
        let modificado = false;
        const { code, message } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/checklist/actualizar-preguntas-checklist/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            modificado = true;
        }
        if (code === FAILURE) {
            alert(message);
        }
        return modificado;
    } catch (err) {
        console.log("actualizarPreguntasChecklist err");
        console.log(err);
        return false;
    }
}

export async function obtenerPreguntasChecklist(obj) {
    try {
        console.log('obtenerPreguntasChecklist');
        console.log(obj);
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/checklist/obtener-preguntas-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerPreguntasChecklist err");
        console.log(err);
        return [];
    }
}

export async function obtenerChecklistRealizadoPorUsuarioMovil() {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/checklist/obtener-checklist-realizado-por-usuario-movil/",
            {
                headers: getHeaders(),
                method: "POST",
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            for (let i = 0; i < body.length; i++) {
                body[i].fechaProceso = moment(body[i].fechaProceso).format('DD/MM/YYYY HH:mm');
                if (moment(body[i].fechaActualChecklist).isValid() === true) {
                    body[i].fechaActualChecklist = moment.utc(body[i].fechaActualChecklist).format('DD/MM/YYYY');
                }
                if (moment(body[i].fechaProximoChecklist).isValid() === true) {
                    body[i].fechaProximoChecklist = moment.utc(body[i].fechaProximoChecklist).format('DD/MM/YYYY');
                }
            }
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaChecklist err");
        console.log(err);
        return [];
    }
}

export async function deshabilitarRespuestaChecklist(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/checklist/deshabilitar-respuesta-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarRespuestaChecklist err");
        console.log(err);
        return false;
    }
}

export async function obtenerDetalleRespuestaChecklist(obj) {
    try {
        let rows = [];
        const { body, code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/checklist/detalle-respuesta-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerDetalleRespuestaChecklist err");
        console.log(err);
        return [];
    }
}

export async function obtenerDatosDashboardChecklist(obj) {
    try {
        let rows = null;
        const { body, code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/checklist/obtener-datos-dashboard/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerDatosDashboardChecklist err");
        console.log(err);
        return null;
    }
}